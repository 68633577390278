import { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import QRCodeImg from '../../images/qr-code.png'
import { Logo } from '../../cmp/Logo'
import TwitterLogo from '../../images/mobile_web_app/footer/twitter.svg'
import LinkedinLogo from '../../images/mobile_web_app/footer/linkedin.svg'

function QRCode() {
  const [showCard, setShowCard] = useState(false)

  useEffect(() => {
    setShowCard(true)
  }, [])

  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={showCard}
      ref={nodeRef}
      timeout={1000}
      classNames="card-transition"
      unmountOnExit
    >
      <main className='card'>
        <section>
          <Logo />

          <hr className='line'></hr>

          <div className='download'>
            <h1>Download the app</h1>
            <p>Scan the QR code below to download Unbanx on your phone.</p>
            <img className='qrCode' src={QRCodeImg} alt='QRCode' />
            <p>Let your data work for you. Connect your bank account to unlock super powered rewards.</p>
          </div>

          <hr className='line bottom'></hr>

          <footer className='social'>
            <ul>
              <li>
                <a href="https://twitter.com/Unbanx_app" aria-label="Twitter">
                  <img className='twitterLogo' src={TwitterLogo} />
                </a>
              </li>

              <li>
                <a className='linkedinLogo' href="https://www.linkedin.com/company/unbanx/" aria-label="LinkedIn">
                  <img className='linkedinLogo' src={LinkedinLogo} />
                </a>
              </li>
            </ul>

            <p>Say hello or ask us a quesiton <a href="mailto:hello@unbanx.me">hello@unbanx.me</a>.</p>
          </footer>
        </section>
      </main>
    </CSSTransition>
  )
}

export { QRCode }
