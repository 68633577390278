import { useNavigate } from 'react-router-dom'

import LogoSvg from '../images/mobile_web_app/Logo.svg'

function Logo() {
  let navigate = useNavigate()

  function goHome() {
    navigate('/')
  }

  return (
    <img className="logo" src={LogoSvg} alt="Unbanx Logo" onClick={goHome} />
  )
}

export { Logo }
