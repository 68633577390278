import {
  Routes,
  Route,
} from 'react-router-dom'

import Pkg from '../package.json'

import { QRCode } from './feat/qr-code/QRCode'

import './App.css'

function App() {
  const version = Pkg.version
  console.log(`version: ${version}`)

  return (
    <Routes>
      <Route
        path='/*'
        element={
          <QRCode />
        }
      />
    </Routes>
  )
}

export default App
