import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'

let locm = document.location.href.match(/^(http.?:\/\/)www\.(.*)$/)
if (locm) {
  document.location = locm[1] + locm[2]
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  // We need to disable React.StrictMode because of the third-party lib
  // CSSTransition. It uses the deprecated method findDOMNode.
  // Otherwise, on development, the following warning is logged:
  // "Warning: findDOMNode is deprecated in StrictMode..."
  // It's only a temporary solution. Once WebApp is active again,
  // we should enable React.StrictMode.
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
)
